export default {
  //id: null,
  name: null,
/*  checkbox_ok: true,*/
  checkbox_pruefen: false,
  checkbox_flecken: false,
  checkbox_legung: false,
  checkbox_defekte: false,
  checkbox_menge: false,
  checkbox_anderes: false,
  notiz: null,
  images: [],
  images_names: []
};
